import { PriceToggle } from '@/components/PriceToggle'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { api } from '@/lib/api'
import { formatDateWithTime } from '@/lib/formatting'
import { cn, getBlockUrl, getOrdinalExplorerUrl } from '@/lib/utils'
import { IArchive } from '@/types'
import {
  CheckCircleIcon,
  ExternalLinkIcon,
  ChevronDownIcon,
  BitcoinIcon,
  LoaderCircleIcon,
} from 'lucide-react'
import { useState } from 'react'

interface Props {
  archive: IArchive
  openPaymentModal: () => void
}

export function OnChainDetails(props: Props) {
  const { archive, openPaymentModal } = props

  const { data: btcInfo } = api.ordinals.btcFees.useQuery()

  const [detailsOpen, setDetailsOpen] = useState(false)

  const hashOnlySaved = archive.hashTx?.txid && !archive.fullTextTx?.txid

  return (
    <Alert variant="theme">
      <CheckCircleIcon className="h-4 w-4" />
      <AlertTitle>Saved on Bitcoin</AlertTitle>
      <AlertDescription>
        <div>
          <div>
            This page{hashOnlySaved ? `'s hash` : ''} is saved on Bitcoin.
          </div>
          {hashOnlySaved ? (
            <Button
              className="mt-2 font-sans"
              variant="theme"
              onClick={openPaymentModal}
            >
              <BitcoinIcon className="mr-1 h-4 w-4" /> Save Full Page
            </Button>
          ) : null}
        </div>
        <Collapsible open={detailsOpen} onOpenChange={setDetailsOpen}>
          <CollapsibleContent
            className={cn(
              'grid grid-cols-1 gap-x-4 gap-y-2 font-mono text-xs sm:grid-cols-[auto,1fr]',
              {
                'mt-4': detailsOpen,
              },
            )}
          >
            {!btcInfo ? (
              <div className="flex items-center justify-center">
                <LoaderCircleIcon className="h-4 w-4 animate-spin" />
              </div>
            ) : null}
            {archive.hashTx && btcInfo && (
              <Details
                fullText={false}
                blockTimestamp={archive.hashTx.blockTimestamp}
                ordinalId={archive.hashTx.ordinalId}
                blockHeight={archive.hashTx.blockHeight}
                blockHash={archive.hashTx.blockHash}
                feeRate={archive.hashTx.satsPerByte}
                totalFeeSats={archive.hashTx.totalSats}
                bitcoinPrice={btcInfo.price}
              />
            )}
            {archive.fullTextTx && btcInfo ? (
              <>
                {archive.hashTx ? <div className="col-span-2 mt-4" /> : null}
                <Details
                  fullText={true}
                  blockTimestamp={archive.fullTextTx.blockTimestamp}
                  ordinalId={archive.fullTextTx.ordinalId}
                  blockHeight={archive.fullTextTx.blockHeight}
                  blockHash={archive.fullTextTx.blockHash}
                  feeRate={archive.fullTextTx.satsPerByte}
                  totalFeeSats={archive.fullTextTx.totalSats}
                  bitcoinPrice={btcInfo.price}
                />
              </>
            ) : null}
          </CollapsibleContent>
          <div
            className={`flex justify-start ${cn({
              'mt-4': detailsOpen,
              'mt-2': !detailsOpen,
            })}`}
          >
            <CollapsibleTrigger className="text-theme flex items-center gap-0.5 text-sm underline-offset-4 sm:hover:underline">
              {detailsOpen ? 'Hide' : 'More'} details{' '}
              <ChevronDownIcon
                className={cn({
                  '-rotate-180': detailsOpen,
                  'transition-transform': true,
                })}
              />
            </CollapsibleTrigger>
          </div>
        </Collapsible>
      </AlertDescription>
    </Alert>
  )
}

function Details(props: {
  fullText: boolean
  blockTimestamp: number
  ordinalId: string
  blockHeight: number
  blockHash: string
  /** Sats per byte */
  feeRate: number
  totalFeeSats: number
  bitcoinPrice: number
}) {
  const {
    fullText,
    blockTimestamp,
    ordinalId,
    blockHeight,
    blockHash,
    feeRate,
    bitcoinPrice,
    totalFeeSats,
  } = props

  return (
    <>
      <div className="font-semibold">Type:</div>
      <div>
        <Badge
          variant={fullText ? 'default' : 'secondary'}
          className="uppercase"
        >
          {fullText ? 'Full Text' : 'Hash Only'}
        </Badge>
      </div>
      <div className="font-semibold">Transaction:</div>
      <a
        href={getOrdinalExplorerUrl(ordinalId)}
        target="_blank"
        rel="noreferrer noopener"
        className="text-theme flex items-center gap-1 justify-self-start text-xs underline-offset-4 hover:underline"
        style={{ wordBreak: 'break-all' }}
      >
        {ordinalId} <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
      </a>
      <div className="font-semibold">Block Timestamp:</div>
      <div>{formatDateWithTime(blockTimestamp * 1000)}</div>

      <div className="font-semibold">Block Hash:</div>
      <div className="break-words">{blockHash}</div>
      <div className="font-semibold">Block Height:</div>
      <a
        href={getBlockUrl(blockHeight)}
        target="_blank"
        rel="noreferrer noopener"
        className="text-theme flex items-center gap-1 justify-self-start text-xs underline-offset-4 hover:underline"
        style={{ wordBreak: 'break-all' }}
      >
        {blockHeight} <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
      </a>
      <div className="font-semibold">Fee Rate:</div>
      <div>{feeRate} sats/byte</div>
      <div className="font-semibold">Total Fees:</div>
      <PriceToggle
        sats={totalFeeSats}
        bitcoinPrice={bitcoinPrice}
        className="cursor-pointer"
      />
    </>
  )
}
