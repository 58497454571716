import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import { api } from './lib/api'
import { httpBatchLink } from '@trpc/client'
import { Routes } from './routes'
import { BrowserRouter } from 'react-router-dom'
import { PostHogProvider } from 'posthog-js/react'
import * as Sentry from '@sentry/react'

import { PageView } from './PageView'
import { ThemeProvider } from './components/ThemeProvider'

function Providers({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient())
  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        httpBatchLink({
          url: '/api/trpc',
        }),
      ],
    }),
  )

  return (
    <Sentry.ErrorBoundary
      fallback={(error) => (
        <div className="flex h-dvh flex-col items-center justify-center">
          <div className="text-2xl font-bold">An error occurred</div>
          <div className="text-muted-foreground text-sm">
            {error.error instanceof Error
              ? error.error.message
              : 'Unknown error'}
          </div>
        </div>
      )}
    >
      <PostHogProvider
        apiKey={
          import.meta.env.PROD
            ? 'phc_CLBc09VZ4SBtUupzsbXVdXWh8JVWEFOhcoKbJo28MzQ'
            : undefined
        }
        options={{ capture_pageview: false, api_host: '/api/ph' }}
      >
        <ThemeProvider>
          <BrowserRouter>
            <api.Provider client={trpcClient} queryClient={queryClient}>
              <QueryClientProvider client={queryClient}>
                {children}
              </QueryClientProvider>
            </api.Provider>
            <PageView />
          </BrowserRouter>
        </ThemeProvider>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  )
}

function App() {
  return (
    <Providers>
      <Routes />
    </Providers>
  )
}

export default App
